<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="ml-4">
          <b-img class="logo-size" :src="imgLogo" alt="Divvy" />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="6" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mt-3 mb-5 text-center">
            Parabéns e seja muito bem vindo!
          </b-card-title>

          <b-card-text class="text-center mt-3 mb-5">
            <span>
              Você concluiu o seu cadastro e agora você faz parte do time Divvy.
              Em alguns minutos você receberá um e-mail com seu login e senha
              habilitados para entrar na plataforma!
            </span>
          </b-card-text>

          <b-card-text class="text-center mt-5">
            <b-button variant="primary" block>
              <feather-icon icon="CheckIcon" />
            </b-button>
          </b-card-text>

          <b-card-text class="text-center mt-3">
            <b-link :to="{ name: 'login' }" class="back">
              <span>Voltar para tela de login</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->

      <!-- Left Text-->
      <background-card />
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import BackgroundCard from './template/BackgroundCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BackgroundCard,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/login/img-login.png'),
      // eslint-disable-next-line global-require
      imgLogo: require('@/assets/images/logo/logo.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
}
</script>

<style lang="scss">
.logo-size {
  width: 40px;
}
a.back {
  color: #000;
  font-weight: 500;
}
.img-login {
  background-image: url('~@/assets/images/pages/login/img-login-crop.png');
  background-position: right;
  background-size: auto auto;
  background-repeat: no-repeat;
}
.text-login-type-one {
  width: 400px !important;
  margin-left: auto;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
.text-login-type-one-point-color {
  color: #f8ac18;
}
.text-login-type-two {
  margin-left: auto;
  color: #fff;
  font-size: 14px;
}
a.forgot-password {
  color: #5e5873;
}
@import '@core/scss/vue/pages/page-auth.scss';
</style>
